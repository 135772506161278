import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';

import { Input, InputStyle } from '../shared/form-control/Input';
import PageLoader from '../shared/page-loader/PageLoader';
import { ClientMaintainDto } from '../../models/Client';
import { Option } from '../Option';
import { InputSuggestion } from '../shared/form-control/InputSuggestions';
import ClientService from '../../services/ClientService';
import LanguageSelector from '../shared/LanguageSelector';
import { useCurrentClient } from '../../global-state/Clients';
import { useCurrentUser } from '../../global-state/Auth';

type ClientManageProps = {
  formik: FormikProps<ClientMaintainDto>;
};

const ClientManage: FC<ClientManageProps> = ({ formik }: ClientManageProps) => {
  const { handleChange, handleBlur, values, errors } = formik;
  const [fetchingData, setFetchingData] = useState(true);
  const { t } = useTranslation(['client-manage', 'form']);
  const [parentClient, setParentClient] = useState('');
  const [clients, setClients] = useState<Option<string, string>[]>([]);
  const currentClient = useCurrentClient((x) => x.value);
  const [disableParent, setDisableParent] = useState(false);
  const currentUser = useCurrentUser((x) => x.value);

  useEffect(() => {
    setFetchingData(true);
    ClientService.getMyClients().then((res) => {
      setFetchingData(false);
      setParentClient(res.data.find((client) => client.id === values.client.parentId)?.name || '');
      setClients(
        res.data
          .filter((client) => client.id !== currentClient?.id && !client.parentId)
          .map((client) => {
            return { id: client.id, text: client.name, value: client.name };
          }),
      );
      setDisableParent(res.data.some((client) => !!client.parentId && client.parentId === currentClient?.id));
    });
  }, [currentClient?.id, currentUser?.id, values.client.parentId]);

  return (
    <PageLoader loading={fetchingData}>
      <div>
        <div className="border-gray-5 flex flex-wrap border-b-2 pb-8">
          <div className="w-1/2 pr-4">
            <Input
              data-cy="client-name"
              name="client.name"
              label={t('client-manage:details.fields.company.title')}
              placeholder={t('client-manage:details.fields.company.title')}
              value={values.client.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.client?.name}
              maxLength={100}
            />
          </div>
          <div className="w-1/2 pl-4">
            <Input
              data-cy="client-accountNumber"
              name="client.accountNumber"
              label={t('client-manage:details.fields.account.title')}
              placeholder={t('client-manage:details.fields.account.title')}
              value={values.client.accountNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.client?.accountNumber}
              maxLength={50}
            />
          </div>
          {!disableParent && (
            <div className="w-1/2 pr-4">
              <InputSuggestion
                data-cy="client-parent"
                name="client.parentId"
                suggestions={clients}
                inputConfig={{
                  value: parentClient,
                  placeholder: t('client-manage:details.fields.parent.title'),
                  label: t('client-manage:details.fields.parent.title'),
                  style: InputStyle.NORMAL,
                }}
                onChange={(value) => {
                  setParentClient(value);
                  if (value.length === 0) {
                    handleChange({ target: { name: `client.parentId`, value: null } });
                  }
                }}
                onPick={(option) => {
                  handleChange({ target: { name: `client.parentId`, value: option.id } });
                  setParentClient(option.text);
                }}
              />
            </div>
          )}
          <div className={`w-1/2 ${disableParent ? 'pr-4' : 'pl-4'}`}>
            <LanguageSelector
              label={t('client-manage:details.fields.language.title')}
              setCurrentUserLanguage={false}
              onLanguageChange={(language) => {
                handleChange({ target: { name: `client.language`, value: language } });
              }}
              value={values.client.language}
            />
          </div>
        </div>
      </div>
    </PageLoader>
  );
};

export default ClientManage;
