import { TFunction } from 'i18next';
import * as yup from 'yup';

export interface ClientProps {
  maintainClient: yup.AnyObjectSchema;
}

const maintainClient = (t: TFunction<'schemas'>): yup.AnyObjectSchema => {
  const requiredString = yup.string().required(t('common.required'));
  return yup.object({
    client: yup.object({
      name: requiredString,
      accountNumber: yup.string(),
    }),
  });
};

const client = (t: TFunction<'schemas'>): ClientProps => ({
  maintainClient: maintainClient(t),
});

export default client;
