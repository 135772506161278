import { TFunction } from 'i18next';
import * as yup from 'yup';

export interface UserProps {
  maintainUser: yup.AnyObjectSchema;
}

const maintainUser = (t: TFunction<'schemas'>): yup.AnyObjectSchema => {
  const requiredString = yup.string().required(t('common.required'));
  return yup.object({
    email: yup.string().email(t('common.invalid-email-address')).required(t('common.required')),
    firstName: requiredString,
    lastName: requiredString,
    languageCode: requiredString,
    phoneNumber: yup.string(),
  });
};

const user = (t: TFunction<'schemas'>): UserProps => ({
  maintainUser: maintainUser(t),
});

export default user;
